import { useQuery } from '@tanstack/react-query';

import { fetchLevel2Data } from "../../util/Http";
import { Column, Level2 } from "../../models/Level2";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatNumberInput } from '../../util/formatNumberInput';
import useIsMarketActive from '../../hooks/useIsMarketActive';

interface Level2TableProps {
    stock: string;
}

const columns: readonly Column[] = [
    { id: 'bidmm', label: 'MM', minWidth: 2, align: "center" },
    { id: 'bid', label: 'Bid', minWidth: 2, align: "center" },
    { id: 'bidsize', label: 'Size', minWidth: 2, align: "center", borderRight: '1px solid #D2D4DC' },
    { id: 'askmm', label: 'MM', minWidth: 2, align: "center" },
    { id: 'ask', label: 'Ask', minWidth: 2, align: "center" },
    { id: 'asksize', label: 'Size', minWidth: 2, align: "center" },
  ];

export default function Level2Table ({ stock }: Level2TableProps) {
    const marketActive = useIsMarketActive();
    const { data, isLoading, isError} = useQuery<Level2>({
        queryKey: ["level2", stock],
        queryFn: () => fetchLevel2Data(stock),
        enabled: marketActive
    });

    if (!marketActive) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                The market is currently closed😔 <br />
                Market Hours: 9:30 AM to 4:00 PM EDT, Monday to Friday.
            </div>
        )    
    }

    if (isLoading) {
        return <div style={{display: "flex", justifyContent: "center", padding: "20px"}}>Loading...</div>
    }
    
    if (isError) {
        return <div>Error with fetching level 2</div>
    }

    const bidLength = data?.bid.length || 0;
    const askLength = data?.ask.length || 0;
    const maxLength = Math.max(bidLength, askLength);

    return (
        <Paper sx={{margin: '20px', overflow: 'hidden', marginTop: "5px", background: "#06085e"}}>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, backgroundColor: "#3f51b5", borderRight: column.borderRight, padding: "10px" }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from({ length: maxLength }).map((_, index) => {
                            const bidRow = data?.bid[index];
                            const askRow = data?.ask[index];
                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" }}>{bidRow ? bidRow.marketMaker : ''}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" }}>{bidRow ? bidRow.price : ''}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" ,borderRight: '1px solid #D2D4DC' }}>{bidRow ? formatNumberInput(bidRow.size) : ''}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" }}>{askRow ? askRow.marketMaker: ''}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" }}>{askRow ? askRow.price : ''}</TableCell>
                                    <TableCell align="center" sx={{ fontSize: '0.7rem', color: '#D2D4DC', padding: "10px" }}>{askRow ?formatNumberInput(askRow.size) : ''}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
