import React from 'react';
import { Outlet } from 'react-router-dom';

import SearchAppBar from '../UI/NavBar';

const Layout: React.FC = () => {
  return (
    <div>
      <SearchAppBar />
      <Outlet />
    </div>
  );
};

export default Layout; 