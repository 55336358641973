import { useQuery } from "@tanstack/react-query";

import { fetchStocks } from "../util/Http";
import { Stock } from "../models/Stock";
import StockCard from "../components/UI/StockCard";
import LoadingOverlay from "../components/UI/LoadingOverlay";
import styles from './StockPage.module.css';


const StocksPage: React.FC = () => {
    const { data, isLoading, isError } = useQuery<Stock[]>({
        queryKey: ["stocks"],
        queryFn: fetchStocks
      });


    if (isLoading) {
        return <LoadingOverlay />
    }
    
    if (isError) {
        return <div>Error</div>
    }
        
    return (
        <div className={styles.background}>
            {data?.map((stock) => (
                <div key={stock.symbol}>
                    <StockCard stock={stock} />
                    <p>/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\</p>
                </div>
            ))}
        </div>
    );
}

export default StocksPage

