import React from 'react';

import { Box, Typography } from '@mui/material';

import { Stock } from '../../models/Stock';
import styles from './StockCard.module.css';
import TiersFilter from './TiersFilter';
import Badges from './Badges';

interface StockCardProps {
    stock: Stock;
}

const StockCard: React.FC<StockCardProps> = ({ stock }) => {
    return (
        <Box className={styles.card}>
            <Box className={styles.stockHeader}>
                <h1 className={styles.companyName}>
                    {stock.name}
                    <p className={styles.symbol}>{stock.symbol}</p>
                </h1>
                <Box className={styles.tier}>
                    <TiersFilter tier={stock.tier}/>
                    {stock.tier !== 'OTCQB' && (
                    <p className={styles.tierText}>{stock.tier}</p>
                    )}
                </Box>
                <Box className={styles.priceContainer}>
                    <p className={styles.lastPrice}>{stock.last_price}</p>
                    <p className={styles.usd}>USD</p>
                </Box>
            </Box>
                <Badges
                    verifiedProfile={stock.verified_profile}
                    transferAgentVerified={stock.transfer_agent_verified}
                    hasTwoIndDir={stock.has_two_ind_dir}
                    isPennyStockExempt={stock.is_penny_stock_exempt}
                    is12g32b={stock.is12g32b}
                    textStyle={styles.verifiedText}/>
        </Box>
    );
};

export default StockCard;