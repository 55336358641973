import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { useQuery } from "@tanstack/react-query";

import { fetchStock } from "../util/Http";
import { Stock } from "../models/Stock"; 
import StockCard from '../components/UI/StockCard';
import LoadingOverlay from '../components/UI/LoadingOverlay';
import styles from './StockPage.module.css';
import { logEvent } from '../util/Amplitude';
import { AmplitudeEventsEnum } from '../models/AmplitudeEvents';
import StockTabbedBar from '../components/UI/StockTabbedBar';

const StockPage: React.FC = () => {   
    const { stock } = useParams<{ stock: string }>();

    useEffect(() => {
        logEvent(AmplitudeEventsEnum.PAGE_VIEW, { page: `stock page: ${stock}` });
      }, []);
    
    const { data, isLoading, isError} = useQuery<Stock[]>({
        queryKey: [stock],
        queryFn: () => fetchStock(stock as string)
    });
    
    
    if (isLoading) {
        return <LoadingOverlay />
    }
    
    if (isError) {
        return <h1>Stock Not Found</h1>
    }
  
    return (
        <div className={styles.background}>
            {data?.map((stock) => (
                <StockCard key={stock.symbol} stock={stock} />
            ))}
            <StockTabbedBar stockName={stock || '' } />
        </div>
    );
}

export default StockPage
