import { useState } from 'react';

import { Tabs, Tab, Box } from '@mui/material';

import Level2Table from './Level2Table';
import DilutionChart from './DilutionChart';
import StockOverviewContent from './StockOverviewContent';

type TabName = 'overview' | 'level2' | 'dilution';

interface StockTabbedProps {
  stockName: string;
}

export default function StockTabbedBar({ stockName }: StockTabbedProps) {
  const [selectedTab, setSelectedTab] = useState<TabName>('overview');

  const handleChange = (event: React.SyntheticEvent, newValue: TabName) => {
    setSelectedTab(newValue);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'overview':
        return <StockOverviewContent />;
      case 'level2':
        return <Level2Table stock={stockName} />;
      case 'dilution':
        return <DilutionChart stock={stockName}/>;
      default:
        return <StockOverviewContent />;
    }
  };

  return (
    <Box>
      <Box>
      <Box display="flex" justifyContent="left" paddingBottom="5px" paddingTop="5px">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="inherit"
            indicatorColor="primary"
            sx={{
                '& .MuiTab-root': { color: 'white' },
                '& .MuiTabs-indicator': {
                  background: 'linear-gradient(135deg, #ece9e6, #ffffff)',
                },
              }}
          >
            <Tab value="overview" label="Overview" />
            <Tab value="level2" label="Level 2" />
            <Tab value="dilution" label="dilution" />
          </Tabs>
        </Box>
        <Box>
          {renderContent()}
        </Box>
      </Box>
    </Box>
  );
}