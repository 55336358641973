export default function useIsMarketActive() {
  const now = new Date();
  const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      hour: '2-digit',
      minute: '2-digit',
      weekday: 'long'
  };
  
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(now);
  
  const dayPart = parts.find(part => part.type === 'weekday');
  const hourPart = parts.find(part => part.type === 'hour');
  const minutePart = parts.find(part => part.type === 'minute');

  if (!dayPart || !hourPart || !minutePart) {
      return false;
  }

  const isWeekday = dayPart.value !== 'Saturday' && dayPart.value !== 'Sunday';
  const hourNum = parseInt(hourPart.value);
  const minuteNum = parseInt(minutePart.value);

  return isWeekday && (hourNum > 9 || (hourNum === 9 && minuteNum >= 30)) && hourNum < 16;
}