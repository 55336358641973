import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { LineChart } from '@mui/x-charts/LineChart';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { DilutionData, VisibleSeries } from '../../models/Dilution';
import { fetchDilutionData } from '../../util/Http';
import getDilutionDataset from '../../util/getDilutionDataset';

interface DilutionDataProps {
    stock: string;
}

export default function DilutionChart({ stock }: DilutionDataProps) {
    const [visibleSeries, setVisibleSeries] = useState<VisibleSeries>({
        unrestrictedShares: true,
        restrictedShares: true,
        dtcShares: true,
        authorizedShares: true,
    });

    const { data, isLoading, isError} = useQuery<DilutionData>({
        queryKey: ["DilutionData", stock],
        queryFn: () => fetchDilutionData(stock)
    });

    if (isLoading) {
        return <div style={{display: "flex", justifyContent: "center", padding: "20px"}}>Loading...</div>
    }
    
    if (isError || !data) {
        return <div>Error with fetching dilution data</div>
    }


    const handleToggle = (seriesId: keyof VisibleSeries) => {
        setVisibleSeries(prev => ({
            ...prev,
            [seriesId]: !prev[seriesId],
        }));
    };
    
    const {chartDates, seriesData} = getDilutionDataset(data)
    const filteredData = seriesData.filter(series => visibleSeries[series.id as keyof VisibleSeries]);

      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#131722', margin: '20px', backgroundColor: 'white' }}>      
                <LineChart
                      dataset={chartDates}
                      xAxis={[
                        {
                          id: 'date',
                          dataKey: 'dates',
                          scaleType: 'time',
                          valueFormatter: (date) => `${date.getMonth() + 1}/${date.getFullYear().toString().slice(2)}`,
                        },
                      ]}
                    sx={{padding: '20px', paddingRight: '0px', marginLeft: '30px'}}
                    series={filteredData}
                    grid={{ vertical: true, horizontal: true }}
                    height={300}
                />
            <Box>
                {Object.keys(visibleSeries).map(seriesId => (
                    <FormControlLabel
                        key={seriesId}
                        control={
                        <Checkbox
                            checked={visibleSeries[seriesId as keyof VisibleSeries]}
                            onChange={() => handleToggle(seriesId as keyof VisibleSeries)}
                            size="small"
                            sx={{marginRight: 0}}
                        />
                        }
                        label={seriesId.charAt(0).toUpperCase() + seriesId.slice(1)}
                        sx={{ fontFamily: 'sans-serif', fontSize: '5px', color: 'black' }}
                    />      
                ))}
            </Box>
        </Box>
    );
}
